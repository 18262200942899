import Image from 'next/image';
import { FunctionComponent, memo } from 'react';

import welcomeMobile from '../../../public/img/mobile/welcome.webp';
import welcome from '../../../public/img/welcome.webp';
import useBreakpoints from '../../Hooks/useBreakpoints';
import classes from './styles.module.css';
const SideImage: FunctionComponent = () => {
  const { isDesktop } = useBreakpoints();
  return isDesktop ? (
    <Image
      src={welcome.src}
      alt={'welcome'}
      width="1150px"
      height="650px"
      className={classes.welcome}
    />
  ) : (
    <Image
      src={welcomeMobile.src}
      alt={'welcome'}
      width={'360px'}
      height={'456px'}
    />
  );
};

export default memo(SideImage);
